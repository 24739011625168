import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"

import { Status } from "../components/CheckboxCell"
import {
  BrandsResponse,
  CheckboxEditBrandTypes,
  PartnershipCreateResponse,
  PartnershipsResponse,
} from "../types/types"
import { ENV } from "../utils/env"
import { getCookieByName } from "../utils/session"
import { processErrorResponse } from "./auth"

export const brandsApi = createApi({
  reducerPath: "brandsApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${ENV.BUSINESS_CONSUMER}/api`,
    credentials: "include",
    headers: {
      "Accept": "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
      'X-TF-ECOMMERCE': getCookieByName('X-TF-ECOMMERCE'),
    },
  }),
  tagTypes: ["Brands", "Partnerships"],
  endpoints: (build) => ({
    getBrands: build.query<
      BrandsResponse,
      { search_query: string; page: number; limit: number }
    >({
      query: ({ search_query, page, limit }) => ({
        url:
          "/internal-brands?" +
          new URLSearchParams({
            search_query,
            page: String(page),
            limit: String(limit),
          }),
      }),
      providesTags: () => [{ type: "Brands" }],
    }),
    getPartnerships: build.query<PartnershipsResponse, void>({
      query: () => ({
        url: "/partnerships",
      }),
      providesTags: () => [{ type: "Partnerships" }],
    }),
    createPartnership: build.mutation<
      PartnershipCreateResponse,
      {
        logo: {
          url: string
        }
        title: string
        slug: string
      }
    >({
      transformErrorResponse: (response) => processErrorResponse("Error when creating partnership!", response),
      query: (patch) => ({
        url: `/partnership`,
        method: "POST",
        body: {
          data: {
            attributes: patch,
          },
        },
      }),
      async onQueryStarted(_args, { dispatch, queryFulfilled }) {
        try {
          const data = await queryFulfilled
          dispatch(
            brandsApi.util.updateQueryData(
              "getPartnerships",
              undefined,
              (draft) => {
                draft.data.push({
                  title: data.data.data.attributes.title,
                  id: String(data.data.data.id),
                  slug: data.data.data.attributes.slug,
                  logo: data.data.data.attributes.logo,
                })
              }
            )
          )
        } catch { }
      },
    }),
    updatePartnership: build.mutation<
      PartnershipCreateResponse,
      {
        id: string
        logo: {
          url: string
        }
        title: string
        slug: string
      }
    >({
      transformErrorResponse: (response) => processErrorResponse("Error when editing partnership!", response),
      query: ({ id, ...patch }) => ({
        url: `/partnership/${id}`,
        method: "PATCH",
        body: {
          data: {
            attributes: patch,
          },
        },
      }),
      async onQueryStarted({ id }, { dispatch, queryFulfilled }) {
        try {
          const data = await queryFulfilled
          dispatch(
            brandsApi.util.updateQueryData(
              "getPartnerships",
              undefined,
              (draft) => {
                const index = draft.data.findIndex(
                  (partnership) => partnership.id === id
                )
                if (index !== -1) {
                  draft.data[index].logo = data.data.data.attributes.logo
                  draft.data[index].slug = data.data.data.attributes.slug
                  draft.data[index].title = data.data.data.attributes.title
                }
              }
            )
          )
        } catch { }
      },
    }),
    removePartnership: build.mutation<
      PartnershipCreateResponse,
      {
        id: string
      }
    >({
      transformErrorResponse: (response) => processErrorResponse("Error when deleting partnership!", response),
      query: ({ id }) => ({
        url: `/partnership/${id}`,
        method: "DELETE",
      }),
      async onQueryStarted({ id }, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled
          dispatch(
            brandsApi.util.updateQueryData(
              "getPartnerships",
              undefined,
              (draft) => {
                const index = draft.data.findIndex(
                  (partnership) => partnership.id === id
                )
                if (index !== -1) {
                  draft.data.splice(index, 1)
                }
              }
            )
          )
        } catch { }
      },
    }),
    updateInternalBrand: build.mutation<
      void,
      {
        id: string
        status: Status
        property: CheckboxEditBrandTypes
        search_query: string
        page: number
        limit: number
      }
    >({
      transformErrorResponse: (response) => processErrorResponse("Error when editing brand!", response),
      query: ({ id, ...patch }) => ({
        url: `/internal-brand/${id}`,
        method: "PATCH",
        body: {
          data: {
            attributes: patch,
          },
        },
      }),
      async onQueryStarted(
        { id, status, property, search_query, page, limit },
        { dispatch, queryFulfilled }
      ) {
        const patchResult = dispatch(
          brandsApi.util.updateQueryData(
            "getBrands",
            { search_query, page, limit },
            (draft) => {
              const index = draft.data.brands.findIndex(
                (brand) => brand.id === id
              )
              if (index !== -1) {
                draft.data.brands[index][property] = !!(status === "on")
              }
            }
          )
        )
        try {
          await queryFulfilled
        } catch {
          patchResult.undo()
        }
      },
    }),
  }),
})

export const {
  useGetBrandsQuery,
  useUpdateInternalBrandMutation,
  useGetPartnershipsQuery,
  useCreatePartnershipMutation,
  useUpdatePartnershipMutation,
  useRemovePartnershipMutation,
} = brandsApi
